import React from 'react';
import { observer } from 'mobx-react';
import t from 'i18n';
import { ExportButton } from 'components';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faX } from '@fortawesome/free-solid-svg-icons';
import { DOWN, UP } from '../../../../constants';
import NoteStore from './store';
import { getReport } from './noteReport';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';
import { Loader } from '@smartplatform/ui';

const CODE = 'NoteReport';

@observer
export default class Note extends React.Component {
	constructor(props) {
		super(props);
		this.store = new NoteStore(CODE, t('fireDepNote.title'), true);
		this.store.init();
	}

	renderFireDep = (fireDep, index) => {
		let content;
		const shift = this.store.shiftData[fireDep.id] || null;
		content = [...new Array(64)].map((v, i) => this.renderFireDepCell(shift, i + 4));
		return (
			<tr key={fireDep.id} className={`firedep-note headorg-${fireDep.headOrgId} firedep-${fireDep.id}` + (!shift ? ' empty' : '')}>
				<td className={shift ? 'number' : 'empty-number'}>{index + 1}</td>
				<td>
					{fireDep.name}
					&nbsp;
					<FontAwesomeIcon icon={faChevronUp} onClick={() => this.changeFieldPosition(fireDep.headOrgId, fireDep.id, UP)} className='cursor-pointer' />
					<FontAwesomeIcon icon={faChevronDown} onClick={() => this.changeFieldPosition(fireDep.headOrgId, fireDep.id, DOWN)} className='cursor-pointer' />
				</td>
				<td>{fireDep.city?.name || ''}</td>
				{content}
			</tr>
		);
	};

	renderFireDepCell = (shift, number) => {
		if (number === 67) {
			return (
				<td key={number} className='value'>
					{shift ? shift['col' + number] || '' : ''}
				</td>
			);
		}
		return (
			<td key={number} className='value'>
				{shift ? parseInt(shift['col' + number]) || '' : ''}
			</td>
		);
	};

	changeFieldPosition = (headOrgId, fireDepId, pos) => {
		const tbody = document.querySelector('tbody');
		const fireDeps = tbody.querySelectorAll(`.headorg-${headOrgId}`);

		let index;
		for (const [i, fireDep] of Array.from(fireDeps).entries()) {
			if (fireDep.classList.contains(`firedep-${fireDepId}`)) {
				index = i;
			}
		}

		const canMove = (index !== 0 && pos !== DOWN) || (index !== fireDeps.length - 1 && pos !== UP);
		if (canMove) {
			fireDeps[index].querySelector('td').innerText = pos === UP ? index : index + 2;
			fireDeps[pos === UP ? index - 1 : index + 1].querySelector('td').innerText = pos === UP ? index + 1 : index + 1;

			if (index === fireDeps.length - 2 && pos === DOWN) {
				tbody.insertBefore(fireDeps[index + 1], fireDeps[index]);
			} else {
				tbody.insertBefore(fireDeps[index], fireDeps[pos === UP ? index - 1 : index + 2]);
			}
			this.store.changeFields(headOrgId, fireDepId, pos);
		}
	};

	deleteField = (id) => {
		const field = document.getElementById(id);
		field.remove();
	};

	renderTotalCell = (name, fireDeps) => {
		const total = {};
		for (let fireDep of fireDeps) {
			const fireDepData = this.store.shiftData[fireDep.id];
			if (fireDepData) {
				if (!total[name]) total[name] = 0;
				total[name] += parseInt(fireDepData[name]) || 0;
			}
		}
		return (
			<td key={name} className='value'>
				{total[name] || '0'}
			</td>
		);
	};

	renderTotal = (division, key) => {
		const TOTAL_FIELD_NAME = {
			'2-0': 'Итого по 5 ПСО',
			2: 'Итого по ФПС',
			'3-0': 'Итого по ОГПС',
			'4-0': 'Итого по ВПО',
			'5-0': 'Итого по ЧПО',
			'6-0': 'Итого по ДПО',
			'7-0': 'Итого по АСФ, ВГСЧ',
			total: 'Всего за Якутский ПСГ',
		};
		const cols = [...new Array(63)].map((v, i) => ({ name: 'col' + (i + 4) }));
		const content = cols.map((v, i) => this.renderTotalCell(v.name, division));
		return (
			<tr className='division-total' key={key} id={key}>
				<td>
					<FontAwesomeIcon icon={faX} onClick={() => this.deleteField(key)} className='cursor-pointer' />
				</td>
				<td colSpan={2}>{TOTAL_FIELD_NAME[key] ?? t('total')}</td>
				{content}
				<td />
			</tr>
		);
	};

	renderHeadOrgs = () => {
		return this.store.headOrgs.map((org) => {
			if (!org.__fireDeps.length && !org.__divisions.length) return;
			return (
				<React.Fragment key={'org-' + org.id}>
					<tr className='head-org'>
						<td colSpan={67}>{org.name}</td>
					</tr>
					{org.__divisions &&
						org.__divisions.map((division, i) => (
							<React.Fragment key={'org-' + org.id + '-div-' + i}>
								{division.map(this.renderFireDep)}
								{(division.length > 1 || org.__divisions.length === 1) && this.renderTotal(division, org.id + '-' + i)}
							</React.Fragment>
						))}
					{org.__divisions.length > 1 &&
						this.renderTotal(
							org.__divisions.flatMap((div) => div.flat()),
							org.id
						)}
				</React.Fragment>
			);
		});
	};

	numberedRow = () => {
		return (
			<tr className='numbered'>
				{[...new Array(67)].map((v, i) => (
					<td key={i}>{i + 1}</td>
				))}
			</tr>
		);
	};

	export = async () => {
		const { shiftData } = this.store;
		const htmlTable = document.getElementById('techfires-note-table');
		let html = htmlTable.outerHTML;
		html = html.replaceAll(' class="vert"', ' style="mso-rotate:90;"');
		const settings = await this.store.getNoteSettings();
		const { filename, content } = getReport(html, settings, shiftData);
		const downloadLink = document.createElement('a');
		downloadLink.href = 'data:application/vnd.ms-excel,\uFEFF ' + encodeURIComponent(content);
		downloadLink.download = filename;
		downloadLink.click();
	};

	render() {
		const allFireDeps = this.store.headOrgs.map((row) => row.__divisions.flatMap((div) => div.flat())).flat();
		const { garrison, onGarrisonChange, loading, exportExcelReport } = this.store;

		return (
			<div className='techfires-note'>
				<h1>{t('fireDepNote.title')}</h1>
				{!this.store.isRadiotelephonist && (
					<div className='mb-2 note-toolbar'>
						<GarrisonSelect value={garrison} onChange={onGarrisonChange} isRequired width={200} />
						<ExportButton onClick={this.export} />
						<ExportButton onClick={exportExcelReport} text={t('exportXLSX')} />
					</div>
				)}
				{loading ? (
					<Loader size={20} />
				) : (
					<table id='techfires-note-table'>
						<thead>
							<tr>
								<th rowSpan={4} className='b-b-n'>
									{t('fireDepNote.number')}
								</th>
								<th rowSpan={4} className='b-b-n'>
									{t('fireDepNote.fireDeps')}
								</th>
								<th rowSpan={4} className='b-b-n'>
									{t('fireDepNote.location')}
								</th>
								<th colSpan={32} className='b-b-n'>
									{t('fireDepNote.vehicles.title')}
								</th>
								<th colSpan={2} rowSpan={3} className='b-b-n'>
									{t('fireDepNote.gasMasks.title')}
								</th>
								<th colSpan={3} rowSpan={3} className='b-b-n'>
									{t('fireDepNote.suits.title')}
								</th>
								<th colSpan={2} rowSpan={3} className='b-b-n'>
									{t('fireDepNote.tools.title')}
								</th>
								<th colSpan={18} rowSpan={2} className='b-b-n'>
									{t('fireDepNote.personnel.title')}
								</th>
								<th colSpan={6} rowSpan={2} className='b-b-n'>
									{t('fireDepNote.foams.title')}
								</th>
								<th rowSpan={4} className='b-b-n'>
									{t('fireDepNote.chief')}
								</th>
							</tr>
							<tr>
								<th colSpan={15} className='b-b-n'>
									{t('fireDepNote.vehicles.active.title')}
								</th>
								<th colSpan={9} rowSpan={2} className='b-b-n'>
									{t('fireDepNote.vehicles.reserve.title')}
								</th>
								<th colSpan={4} rowSpan={2} className='b-b-n'>
									{t('fireDepNote.vehicles.maintenance.title')}
								</th>
								<th colSpan={4} rowSpan={2} className='b-b-n b-r-n'>
									{t('fireDepNote.vehicles.support.title')}
								</th>
							</tr>
							<tr>
								<th colSpan={8} className='b-b-n'>
									{t('fireDepNote.vehicles.active.main.title')}
								</th>
								<th colSpan={7} className='b-b-n'>
									{t('fireDepNote.vehicles.active.special.title')}
								</th>
								<th rowSpan={2} className='vert'>
									{t('fireDepNote.personnel.col43')}
								</th>
								<th rowSpan={2} className='vert'>
									{t('fireDepNote.personnel.col44')}
								</th>
								<th rowSpan={2} className='vert'>
									{t('fireDepNote.personnel.col45')}
								</th>
								<th colSpan={7} className='b-b-n'>
									{t('fireDepNote.personnel.active.title')}
								</th>
								<th rowSpan={2} className='vert'>
									{t('fireDepNote.personnel.col53')}
								</th>
								<th rowSpan={2} className='vert'>
									{t('fireDepNote.personnel.col54')}
								</th>
								<th colSpan={6} className='b-b-n'>
									{t('fireDepNote.personnel.missing.title')}
								</th>
								<th colSpan={2} className='b-b-n'>
									{t('fireDepNote.foams.active.title')}
								</th>
								<th colSpan={2} className='b-b-n'>
									{t('fireDepNote.foams.reserve.title')}
								</th>
								<th colSpan={2} className='b-b-n'>
									{t('fireDepNote.foams.store.title')}
								</th>
							</tr>
							<tr>
								<th className='vert'>{t('fireDepNote.vehicles.active.main.col4')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.active.main.col5')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.active.main.col6')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.active.main.col7')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.active.main.col8')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.active.main.col9')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.active.main.col10')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.active.main.col11')}</th>

								<th className='vert'>{t('fireDepNote.vehicles.active.special.col12')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.active.special.col13')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.active.special.col14')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.active.special.col15')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.active.special.col16')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.active.special.col17')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.active.special.col18')}</th>

								<th className='vert'>{t('fireDepNote.vehicles.reserve.col19')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.reserve.col20')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.reserve.col21')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.reserve.col22')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.reserve.col23')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.reserve.col24')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.reserve.col25')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.reserve.col26')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.reserve.col27')}</th>

								<th className='vert'>{t('fireDepNote.vehicles.maintenance.col28')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.maintenance.col29')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.maintenance.col30')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.maintenance.col31')}</th>

								<th className='vert'>{t('fireDepNote.vehicles.support.col32')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.support.col33')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.support.col34')}</th>
								<th className='vert'>{t('fireDepNote.vehicles.support.col35')}</th>

								<th className='vert'>{t('fireDepNote.gasMasks.col36')}</th>
								<th className='vert'>{t('fireDepNote.gasMasks.col37')}</th>

								<th className='vert'>{t('fireDepNote.suits.col38')}</th>
								<th className='vert'>{t('fireDepNote.suits.col39')}</th>
								<th className='vert'>{t('fireDepNote.suits.col40')}</th>

								<th className='vert'>{t('fireDepNote.tools.col41')}</th>
								<th className='vert'>{t('fireDepNote.tools.col42')}</th>

								<th className='vert'>{t('fireDepNote.personnel.active.col46')}</th>
								<th className='vert'>{t('fireDepNote.personnel.active.col47')}</th>
								<th className='vert'>{t('fireDepNote.personnel.active.col48')}</th>
								<th className='vert'>{t('fireDepNote.personnel.active.col49')}</th>
								<th className='vert'>{t('fireDepNote.personnel.active.col50')}</th>
								<th className='vert'>{t('fireDepNote.personnel.active.col51')}</th>
								<th className='vert'>{t('fireDepNote.personnel.active.col52')}</th>

								<th className='vert'>{t('fireDepNote.personnel.missing.col55')}</th>
								<th className='vert'>{t('fireDepNote.personnel.missing.col56')}</th>
								<th className='vert'>{t('fireDepNote.personnel.missing.col57')}</th>
								<th className='vert'>{t('fireDepNote.personnel.missing.col58')}</th>
								<th className='vert'>{t('fireDepNote.personnel.missing.col59')}</th>
								<th className='vert'>{t('fireDepNote.personnel.missing.col60')}</th>

								<th className='vert'>{t('fireDepNote.foams.active.col61')}</th>
								<th className='vert'>{t('fireDepNote.foams.active.col62')}</th>

								<th className='vert'>{t('fireDepNote.foams.reserve.col63')}</th>
								<th className='vert'>{t('fireDepNote.foams.reserve.col64')}</th>

								<th className='vert'>{t('fireDepNote.foams.store.col65')}</th>
								<th className='vert'>{t('fireDepNote.foams.store.col66')}</th>
							</tr>
						</thead>
						<tbody>
							{this.numberedRow()}
							{this.renderHeadOrgs()}
							{this.renderTotal(allFireDeps, 'total')}
						</tbody>
					</table>
				)}
			</div>
		);
	}
}

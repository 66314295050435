import { observable } from 'mobx';
import store from 'client/store';
import { UP } from '../../../../constants';
import { getDateForShift } from 'techfires/helper';
import { getRoleNames, exportReport } from 'client/tools';
import techFiresStore from 'techfires/techFiresStore';

export default class NoteStore {
	@observable date = new Date();
	@observable shift = null;
	@observable shiftData = {};
	@observable headOrgs = [];
	@observable fieldsOrder = {};
	@observable garrison = techFiresStore.userGarrisons[0];
	@observable loading = true;
	@observable noteSettings = null;

	constructor(code, name, checkReport = false) {
		this.code = code;
		this.name = name;
		checkReport && this.checkReport();
		this.init();
	}

	init = async () => {
		const { start, end } = getDateForShift(this.date);

		await this.loadLocalStorage();
		const whereShifts = { and: [{ date: { gte: start } }, { date: { lte: end } }] };
		if (this.garrison) whereShifts.and.push({ garrisonId: this.garrison.id });
		const shifts = await store.model.TechFireShift.find({
			where: { ...whereShifts },
			order: 'id desc',
			limit: 1,
		});
		this.shift = shifts.length > 0 ? shifts[0] : null;

		const whereOrganization = { parentId: { neq: null } };
		if (this.garrison) whereOrganization.garrisonId = this.garrison.id;
		const headOrgs = await store.model.ViewTechFireOrganization.find({
			where: { ...whereOrganization },
			include: [
				{ relation: 'parent' },
				{ relation: 'children' },
				{
					relation: 'fireDepartments',
					scope: {
						fields: ['id', 'name', 'cityId'],
						include: [
							{ relation: 'city', scope: { fields: ['id', 'name'] } },
							{ relation: 'vehicles', scope: { fields: ['id', 'name'] } },
						],
					},
				},
			],
			order: 'id asc',
		});

		const rows = [];

		const getChildren = (org) => {
			const children = [];
			org.__name = org.name;
			for (let child of org.children()) {
				children.push(child);
				org.__name += ', ' + child.name;
				const _children = getChildren(child);
				_children.forEach((c) => (org.__name += ', ' + c.name));
				children.push(..._children);
			}
			return children;
		};

		for (let org of headOrgs) {
			org.__children = getChildren(org);
			org.__fireDeps = [...org.fireDepartments()];
		}

		for (let org of headOrgs) {
			if (org.parentId !== 2 && (org.__children.length > 0 || org.__fireDeps.length > 0)) {
				rows.push({
					id: org.id,
					name: org.__name,
					__children: org.__children,
					__fireDeps: org.__fireDeps,
				});
			}
		}

		const getDivisions = (row) => {
			const divisions = [];
			for (let child of row.__children) {
				divisions.push(...getDivisions(child));
			}
			if (row.__fireDeps.length > 0) {
				const isInvalidOrder = !this.fieldsOrder[row.id] || this.fieldsOrder[row.id].length !== row.__fireDeps.length;
				if (isInvalidOrder) {
					this.fieldsOrder[row.id] = [];
					for (const fireDep of row.__fireDeps) {
						this.fieldsOrder[fireDep.headOrgId].push(fireDep.id);
					}
				}
				row.__fireDeps.sort((a, b) => this.fieldsOrder[row.id].indexOf(a.id) - this.fieldsOrder[row.id].indexOf(b.id));
				divisions.push(row.__fireDeps);
			}
			return divisions;
		};

		for (let row of rows) {
			row.__divisions = getDivisions(row);
		}

		this.headOrgs = rows;

		if (this.shift) {
			const shiftData = {};
			const noteRows = await this.shift.getNote();

			noteRows.forEach((fireDep) => {
				shiftData[fireDep.id] = fireDep;
			});

			this.shiftData = shiftData;
		}

		this.noteSettings = await this.getNoteSettings();

		this.loading = false;
	};

	get isRadiotelephonist() {
		return getRoleNames().includes('tfires');
	}

	changeFields = (orgId, fireDepId, pos) => {
		const index = this.fieldsOrder[orgId].indexOf(fireDepId);
		const element = this.fieldsOrder[orgId][index];
		const toIndex = pos === UP ? index - 1 : index + 1;
		this.fieldsOrder[orgId].splice(index, 1);
		this.fieldsOrder[orgId].splice(toIndex, 0, element);
		this.saveLocalStorage();
	};

	//local storage
	saveLocalStorage = () => {
		store.local.techfires.note = {
			fieldsOrder: this.fieldsOrder,
		};
		store.local.save();
	};

	loadLocalStorage = async () => {
		const { note } = store.local.techfires;
		if (note?.fieldsOrder) {
			this.fieldsOrder = note.fieldsOrder;
		}
	};

	onGarrisonChange = (value) => {
		this.garrison = value;
		this.init();
	};

	getNoteSettings = async () => {
		const noteSettings = {
			headNote: '',
			responsiblePerson: [],
			dutyShift: [],
		};

		if (this.garrison) {
			const settings = await store.model.Setting.find({
				where: { code: { inq: Object.keys(noteSettings).map((key) => this.generateCode(this.garrison, key)) } },
			});
			for (const setting of settings) {
				const key = setting.code.split('.').at(-1);
				if (['responsiblePerson', 'dutyShift'].includes(key)) {
					noteSettings[key] = JSON.parse(setting.value);
				} else {
					noteSettings[key] = String(setting.value);
				}
			}
		}

		return noteSettings;
	};

	generateCode = (garrison, property) => `noteSettings.${garrison?.code}.${property}`;

	checkReport = async () => {
		if (this.code) {
			const report = await store.model.TechFireReport.find({ where: { code: this.code } });
			this.report = report[0] ?? (await store.model.TechFireReport.create({ code: this.code, name: this.name }));
			if (!this.report.name && this.code) {
				this.report.name = this.name;
				this.report.save();
			}
		}		
	}

	get storeParams() {
		const params = { reportCode: this.code };
		const reportParams = {};

		reportParams.onDate = this.date;
		reportParams.garrison = this.garrison;
		reportParams.headOrgs = this.headOrgs;
		reportParams.shiftData = this.shiftData;
		reportParams.noteSettings = this.noteSettings;

		if (Object.keys(reportParams).length) params.reportParams = reportParams;

		return params;
	}

	getReportData = async () => {
		this.isLoading = true;
		const { data } = await store.model.TechFireReport.getReport(this.storeParams);
		this.report = data;
		this.isLoading = false;
	};

	exportExcelReport = async () => {
		this.isLoading = true;
		const { filename, content } = await store.model.TechFireReport.getReport(this.storeParams);
		exportReport({ filename, content });
		this.isLoading = false;
	}
}

import React from 'react';
import { observer } from 'mobx-react';
import { Toolbar, ListStore } from 'components';
import store from 'client/store';
import t from 'i18n';
import { Column, Table, Pager } from '@smartplatform/ui';
import { observable } from 'mobx';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';
import techFiresStore from 'techfires/techFiresStore';

const FILTER = { include: ['garrison', { relation: 'fireDep', scope: { fields: ['id', 'name'] } }] };

@observer
export class PlanVisitsList extends React.Component {
	@observable garrison = techFiresStore.userGarrisons[0];
	@observable isLoading = true;
	@observable fireDepIds = [];
	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		this.store = new ListStore({
			path: this.props.path,
			model: store.model.viewTechFirePlanVisit,
			filters: this.garrison ? { ...FILTER, where: { garrisonId: this.garrison.id } } : FILTER,
		});
		this.fireDepIds = (await store.model.FireDep.find({ fields: ['id'] })).map((r) => r.id);
		this.isLoading = false;
	};

	componentDidMount() {
		this.store.setPerPage();
	}
	onGarrisonChange = async (value) => {
		this.garrison = value;
		const where = {};
		if (value) {
			const fireDepIds = (await store.model.ViewFireDep.find({ where: { garrisonId: value.id }, fields: ['id'] })).map((r) => r.id);
			where.and = [{ garrisonId: value.id }, { fireDepId: { inq: fireDepIds } }];
		}
		this.store.updateFilters({ ...FILTER, where: { ...where } });
		this.reload();
	};

	visitTypeAndSubType = (record) => {
		if (!record) return '';
		return record.subtypeid ? `${record.typename || ''} / ${record.subtypename || ''}` : `${record.typename || ''}`;
	};

	getFireDeps = (record) => {
		if (!record) return '';
		if (record.typecode === 'RELOCATE') return `${record.firedepfromname} / ${record.firedeptoname}`;
		return record.firedeps;
	};

	renderFireDep = (record) => {
		if (!record) return '';
		if (!this.fireDepIds.includes(record.fireDepId)) return '-';
		return record.fireDep.name;
	};

	reload = () => setTimeout(() => this.store.reload && this.store.reload(), 0);

	render() {
		if (this.isLoading) return null;
		const { tableProps, totalCount, perPage, page, onChange, search, onSearch, create } = this.store;

		return (
			<div className='plan-visits-list'>
				<Toolbar>
					<Toolbar.AddButton onClick={create} />
					<GarrisonSelect value={this.garrison} onChange={this.onGarrisonChange} width={200} />
				</Toolbar>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div id='table'>
					<Table {...tableProps}>
						<Column property='id' label={t('id')} width={80} />
						<Column property='createdAt' label={t('techFire.firesPlanVisits.dateOfCreation')} />
						<Column relation='garrison' property='name' label={t('garrison')} />
						<Column computed={this.visitTypeAndSubType} label={t('techFire.firesPlanVisits.visitType')} />
						<Column computed={this.renderFireDep} label={t('techFireAddress.fireDep')} />
						<Column computed={this.getFireDeps} label={t('techFire.firesPlanVisits.fireDeps')} />
						<Column property='dateOfDeparture' label={t('techFire.firesPlanVisits.dateOfDeparture')} />
						<Column property='dateOfReturn' label={t('techFire.firesPlanVisits.dateOfReturn')} />
					</Table>
				</div>
			</div>
		);
	}
}

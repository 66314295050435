import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Row, Field, NumberInput, RecordSelect, RelationSelect, Input, FormContext, Popup } from '@smartplatform/ui';
import { Form } from 'components';
import { AvatarEdit } from './avatar-edit';
import store from 'client/store';
import t from 'i18n';
import './style.scss';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import OrganizationsTreePopup from './OrganizationsTreePopup';

const renderFireDep = (record) => {
	return (
		<div className='firedep-vehicle-type'>
			<strong>{record.name}</strong>
			<em>{record.headOrg?.name || '-'}</em>
		</div>
	);
};

@observer
export class Profile extends React.Component {
	@observable password = '';
	@observable passwordRepeat = '';
	@observable errors = [];
	@observable isPasswordVisible = false;
	@observable user;
	@observable isLoading = true;
	@observable roles = [];
	@observable isEmailFree = true;
	@observable region = null;
	@observable county = null;
	@observable city = null;
	@observable regionMap = null;
	@observable countyMap = null;
	@observable cityMap = null;
	@observable fireDep = null;
	@observable showOrgTreePopup = false;
	@observable techFireOrganizations = {};
	@observable selectedTechFireOrg = [];

	rolesToAdd = [];
	rolesToRemove = [];
	originalRoleIds = [];

	constructor(props) {
		super(props);
		this.init();
		this.checkEmailAvailable = debounce(this.checkEmailAvailable, 500, { leading: false, trailing: true });
	}

	init = async () => {
		const { id } = this.props.match?.params;
		if (id === 'create') {
			this.user = new store.model.User();
			this.isNew = true;
		} else if (!Number.isNaN(parseInt(id))) {
			this.user = await store.model.User.findById(id, {
				include: [
					{ relation: 'regionMap', scope: { fields: ['id', 'name'] } },
					{ relation: 'countyMap', scope: { fields: ['id', 'name', 'regionId'] } },
					{ relation: 'cityMap', scope: { fields: ['id', 'name', 'countyId', 'regionId'] } },
					{ relation: 'region', scope: { fields: ['id', 'name'] } },
					{ relation: 'county', scope: { fields: ['id', 'name', 'regionId'] } },
					{ relation: 'city', scope: { fields: ['id', 'name', 'countyId', 'regionId'] } },
					{ relation: 'techFireOrganization' },
				],
			});
			this.getRegionCountyCity();
		} else {
			// /profile path
			this.user = store.model.user;
			this.getRegionCountyCity();
		}
		if (!this.isNew) {
			if (store.model.ACL.INFO.WRITE) {
				const roles = await store.model.RoleMapping.find({
					where: {
						principalType: 'USER',
						principalId: this.user.id,
					},
					include: ['role'],
				});
				this.roles = roles.map((rm) => rm.role);
				this.originalRoleIds = this.roles.map(({ id }) => id);
			}

			this.selectedTechFireOrg = await this.user.techFireOrganization();
		}
		this.techFireOrganizations = await store.model.TechFireOrganization.find({
			include: [{ relation: 'children' }, { relation: 'fireDepartments', scope: { fields: ['id'] } }],
		});
		await this.user.organization;
		this.isLoading = false;
	};

	getRegionCountyCity = async () => {
		this.city = this.user.city;

		// для фильтра адресов на карте
		this.countyMap = await this.user.countyMap;
		this.regionMap = await this.user.regionMap;
		this.cityMap = await this.user.cityMap;

		let countyId = this.user.countyId;
		if (!countyId && this.user.city) countyId = this.user.city.countyId;
		if (countyId) {
			this.county = await store.model.County.findById(countyId, {
				scope: { fields: ['id', 'name', 'regionId'] },
			});
		}

		let regionId = this.user.regionId;
		if (!regionId && this.user.county) regionId = this.user.county.regionId;
		if (!regionId && this.user.city) regionId = this.user.city.regionId;
		if (regionId) {
			this.region = await store.model.Region.findById(regionId, {
				scope: { fields: ['id', 'name'] },
			});
		}

		if (this.user.regionId) {
			this.accessLevel = 'region';
		} else if (this.user.countyId) {
			this.accessLevel = 'county';
		} else if (this.user.cityId) {
			this.accessLevel = 'city';
		}
	};

	togglePasswordVisible = () => {
		this.isPasswordVisible = !this.isPasswordVisible;
	};

	onChangePassword = (e) => (this.password = e.target.value);
	onChangePasswordRepeat = (e) => (this.passwordRepeat = e.target.value);

	onAction = () => store.route.history.goBack();

	onAddRole = (role) => {
		const removeIndex = this.rolesToRemove.findIndex((remove) => remove === role.id);
		~removeIndex && this.rolesToRemove.splice(removeIndex, 1);
		const originalIndex = this.originalRoleIds.findIndex((origin) => origin === role.id);
		!~originalIndex && this.rolesToAdd.push(role.id);
		this.roles.push(role);
	};

	onRemoveRole = (e, index) => {
		e.stopPropagation();
		const roleId = this.roles[index].id;
		const addIndex = this.rolesToAdd.findIndex((add) => add === roleId);
		~addIndex ? this.rolesToAdd.splice(addIndex, 1) : this.rolesToRemove.push(roleId);
		this.roles.splice(index, 1);
	};

	beforeSave = async (user) => {
		this.errors = [];

		if (this.password.length) {
			user.password = this.password;
		}

		// записываем только что-то одно (city/county/region), для правильной работы миксина "Member"
		if (this.city) {
			this.user.cityId = this.city.id;
			this.user.countyId = null;
			this.user.regionId = null;
		} else if (this.county) {
			this.user.countyId = this.county.id;
			this.user.cityId = null;
			this.user.regionId = null;
		} else if (this.region) {
			this.user.region = this.region.id;
			this.user.countyId = null;
			this.user.cityId = null;
		}

		// отдельный адрес для карты
		this.user.cityMapId = this.cityMap ? this.cityMap.id : null;
		this.user.cityMap = this.cityMap ? this.cityMap : null;

		this.user.countyMapId = this.countyMap ? this.countyMap.id : null;
		this.user.countyMap = this.countyMap ? this.countyMap : null;

		this.user.regionMapId = this.regionMap ? this.regionMap.id : null;
		this.user.regionMap = this.regionMap ? this.regionMap : null;

		return this.errors.length === 0;
	};

	onSave = async (record) => {
		const promises = [];
		if (this.rolesToAdd.length) {
			for (const roleId of this.rolesToAdd) {
				promises.push(store.model.RoleMapping.create({ principalType: 'USER', principalId: record.id, roleId }));
			}
		}
		if (this.rolesToRemove.length) {
			const rolemapping = await store.model.RoleMapping.find({
				where: { principalType: 'USER', principalId: record.id, roleId: { inq: this.rolesToRemove } },
				fields: ['id'],
			});
			rolemapping.forEach((rm) => rm.delete());
		}
		await Promise.all(promises);
		this.onAction();
	};

	get isMyProfile() {
		return this.user?.id === store.model.user.id;
	}

	onClean = () => {
		this.roles = [];
		this.password = '';
		this.passwordRepeat = '';
		this.isPasswordVisible = false;
	};

	get isValidEmail() {
		if (!this.user.email) return true;
		return String(this.user.email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	}

	get isValidPassword() {
		return this.passwordRepeat.length && this.password === this.passwordRepeat;
	}

	checkEmailAvailable = async () => {
		this.isEmailFree = !(await store.model.User.find({ where: { email: this.user.email }, limit: 1 })).totalCount;
	};

	onRegionChange = async (region) => {
		this.region = region;
		if (this.county && this.county.regionId !== region?.id) this.county = null;
		if (this.city && this.city.regionId !== region?.id) this.city = null;
	};

	onCountyChange = async (county) => {
		this.county = county;
		if (this.city && this.city.countyId !== county?.id) this.city = null;
		if (county) {
			if (county.regionId) {
				this.region = await store.model.Region.findById(county.regionId, {
					scope: { fields: ['id', 'name'] },
				});
			}
		}
	};

	onCityChange = async (city) => {
		this.city = city;
		if (city) {
			if (city.countyId) {
				this.county = await store.model.County.findById(city.countyId, {
					scope: { fields: ['id', 'name', 'regionId'] },
				});
			}
			if (city.regionId) {
				this.region = await store.model.Region.findById(city.regionId, {
					scope: { fields: ['id', 'name'] },
				});
			}
		}
	};

	onRegionMapChange = async (region) => {
		this.regionMap = region;
		if (this.countyMap && this.countyMap.regionId !== region?.id) this.countyMap = null;
		if (this.cityMap && this.cityMap.regionId !== region?.id) this.cityMap = null;
	};

	onCountyMapChange = async (county) => {
		this.countyMap = county;
		if (this.cityMap && this.cityMap.countyId !== county?.id) this.cityMap = null;
		if (county) {
			if (county.regionId) {
				this.regionMap = await store.model.Region.findById(county.regionId, {
					scope: { fields: ['id', 'name'] },
				});
			}
		}
	};

	onCityMapChange = async (city) => {
		this.cityMap = city;
		if (city) {
			if (city.countyId) {
				this.countyMap = await store.model.County.findById(city.countyId, {
					scope: { fields: ['id', 'name', 'regionId'] },
				});
			}
			if (city.regionId) {
				this.regionMap = await store.model.Region.findById(city.regionId, {
					scope: { fields: ['id', 'name'] },
				});
			}
		}
	};

	toggleOrgTreePopup = (boolean) => (this.showOrgTreePopup = boolean);

	render() {
		const { user, onAction, isNew, onAddRole, onSave, isMyProfile, beforeSave, onClean, checkEmailAvailable } = this;
		const { noTitle } = this.props;
		if (this.isLoading) return null;
		const passwordFields = [
			{
				value: this.password,
				disabled: false,
				onChange: this.onChangePassword,
				placeholder: t('password.new'),
				label: t('password.new'),
				className: !this.password.length || this.isValidPassword ? undefined : 'validation-error',
				children: <div className='password-hint'>{t('password.newHint')}</div>,
			},
			{
				value: this.passwordRepeat,
				disabled: !this.password && !this.passwordRepeat,
				onChange: this.onChangePasswordRepeat,
				placeholder: t('password.repeat'),
				className: !this.password.length || this.isValidPassword ? undefined : 'validation-error',
				label: t('password.repeat'),
			},
		];

		const rolesValue =
			this.roles.length > 0 ? (
				this.roles.map((item, i) => (
					<div key={item.id || i} className='item-tag'>
						<span className='item-tag-content'>{item.description}</span>
						<FontAwesomeIcon className='remove-btn' icon={faTimes} onClick={(e) => this.onRemoveRole(e, i)} />
					</div>
				))
			) : (
				<div className='empty-item-tag'> - </div>
			);

		const showRoleSelect = store.model.ACL.INFO.WRITE;

		let disableSave = !this.isValidEmail || !this.isValidPassword || !this.isEmailFree;

		if (user.id) {
			disableSave = this.password.length > 0 && !this.isValidPassword;
		}

		const emailClassName = !this.isValidEmail || !this.isEmailFree ? 'validation-error' : undefined;

		const cityFilter = {
			where: { and: [] },
			scope: { fields: ['id', 'name', 'countyId', 'regionId'] },
		};

		if (this.county) cityFilter.where.and.push({ countyId: this.county.id });
		if (this.region) cityFilter.where.and.push({ regionId: this.region.id });

		const cityMapFilter = {
			where: { and: [] },
			scope: { fields: ['id', 'name', 'countyId', 'regionId'] },
		};

		if (this.countyMap) cityMapFilter.where.and.push({ countyId: this.countyMap.id });
		if (this.regionMap) cityMapFilter.where.and.push({ regionId: this.regionMap.id });

		const isTechFires = store.route.path.indexOf('techfires') !== -1;
		const isPermafrost = store.route.path.indexOf('permafrost') !== -1;

		return (
			<div className='profile-page'>
				{!noTitle && <h2>{t('profile')}</h2>}
				<AvatarEdit record={user} property='avatar' imgWidth={200} imgHeight={200} />
				<Form
					checkRequiredFields={false}
					record={user}
					noDelete={isMyProfile}
					onCancel={onAction}
					onDelete={onAction}
					beforeSave={beforeSave}
					onSave={onSave}
					onClean={onClean}
					stay
					disableSave={disableSave}
				>
					<h3>{t('user.fullName')}</h3>
					<Row>
						<Field property='lastName' label={t('user.lastName')} width={300} />
						<Field property='firstName' label={t('user.firstName')} />
						<Field property='middleName' label={t('user.middleName')} />
					</Row>
					<h3>{t('contact.plural')}</h3>
					<Row>
						<Field property='username' label={t('user.username')} />
						<Field property='telegram' label={t('user.telegram')}>
							<NumberInput integerOnly positiveOnly />
						</Field>
						<Field
							property='email'
							label={
								<span className='email-label'>
									<span>{t('user.email')}</span>
									{!this.isEmailFree && <span className='email-exist'>{t('email.exist')}</span>}
								</span>
							}
							isRequired={!!isNew}
							className='email'
						>
							<Input placeholder='example@gmail.com' className={emailClassName} onChange={checkEmailAvailable} />
						</Field>
					</Row>
					<Row>
						{!isPermafrost && (
							<Field relation='forestry' property='name' label={t('forestry.title')}>
								<RelationSelect noTotal maxItems={10} itemsPerPage={1000} filter={{ fields: ['id', 'name'] }} />
							</Field>
						)}
						{!isTechFires && (
							<Field relation={isPermafrost ? 'permafrostOrganization' : 'organization'} property='name' label={t('user.organizationName')}>
								<RelationSelect noTotal maxItems={10} itemsPerPage={1000} filter={{ fields: ['id', 'name'] }} />
							</Field>
						)}
						<Field property='position' label={t('user.position')} />
					</Row>
					{
						// для работы фильтра на карте
						isTechFires && (
							<Row>
								<div className='form-field'>
									<label>{t('region.title')}</label>
									<RecordSelect
										model={store.model.Region}
										property='name'
										filter={{ scope: { fields: ['id', 'name'] } }}
										value={this.regionMap}
										onChange={this.onRegionMapChange}
										disabled={!store.isAdmin}
									/>
								</div>
								<div className='form-field'>
									<label>{t('county.title')}</label>
									<RecordSelect
										model={store.model.County}
										property='name'
										filter={{
											where: this.regionMap ? { regionId: this.regionMap.id } : undefined,
											scope: { fields: ['id', 'name', 'regionId'] },
										}}
										value={this.countyMap}
										onChange={this.onCountyMapChange}
										disabled={!store.isAdmin}
									/>
								</div>
								<div className='form-field'>
									<label>{t('city.title')}</label>
									<RecordSelect
										model={store.model.City}
										property='name'
										filter={cityMapFilter}
										value={this.cityMap}
										onChange={this.onCityMapChange}
										disabled={!store.isAdmin}
									/>
								</div>
							</Row>
						)
					}
					{!isTechFires && (
						<>
							<Row>
								<div className='form-field'>
									<label>{t('region.title')}</label>
									<RecordSelect
										model={store.model.Region}
										property='name'
										filter={{ scope: { fields: ['id', 'name'] } }}
										value={this.region}
										onChange={this.onRegionChange}
										disabled={!store.isAdmin}
									/>
								</div>
								<div className='form-field'>
									<label>{t('county.title')}</label>
									<RecordSelect
										model={store.model.County}
										property='name'
										filter={{
											where: this.region ? { regionId: this.region.id } : undefined,
											scope: { fields: ['id', 'name', 'regionId'] },
										}}
										value={this.county}
										onChange={this.onCountyChange}
										disabled={!store.isAdmin}
									/>
								</div>
								<div className='form-field'>
									<label>{t('city.title')}</label>
									<RecordSelect
										model={store.model.City}
										property='name'
										filter={cityFilter}
										value={this.city}
										onChange={this.onCityChange}
										disabled={!store.isAdmin}
									/>
								</div>
							</Row>
							<div>
								{t('user.canAccess')}{' '}
								{this.city && (
									<>
										{t('user.limitCity')} {this.city.name} ({this.region?.name} {this.county?.name})
									</>
								)}
								{this.county && !this.city && (
									<>
										{t('user.limitCounty')} {this.county?.name} ({this.region?.name})
									</>
								)}
								{this.region && !this.city && !this.county && (
									<>
										{t('user.limitRegion')} {this.region?.name}
									</>
								)}
								{!this.city && !this.county && !this.region && <>{t('user.allData')}</>}
							</div>
							<Row className='techfire-org'>
								<div className='form-field'>
									<label>{t('organization.plural')}</label>
									<Input value={this.selectedTechFireOrg?.map((x) => x.name).join(', ')} onClick={this.toggleOrgTreePopup} disabled={!store.isAdmin} />
									{this.showOrgTreePopup && store.isAdmin && (
										<FormContext.Consumer>
											{(context) => (
												<Popup onClose={this.toggleOrgTreePopup} width={300}>
													<OrganizationsTreePopup allRecords={this.techFireOrganizations} selected={this.selectedTechFireOrg} context={context} />
												</Popup>
											)}
										</FormContext.Consumer>
									)}
								</div>
							</Row>
							<Row>
								<Field relation='fireDep' computed={renderFireDep} filter={{ include: ['headOrg'] }} label={t('fireDep.title')} disabled={!store.isAdmin} />
								<Field relation='garrison' property='name' filter={{ where: { isGarrison: true } }} label={t('garrison')} disabled={!store.isAdmin} />
							</Row>
						</>
					)}
					<h3>{t('password.title')}</h3>
					<Row>
						{passwordFields.map(({ children, label, ...inputProps }, i) => (
							<div className={classNames('form-field', { disabled: inputProps.disabled })} key={i}>
								<label className={isNew ? 'is-required' : undefined}>{label}</label>
								<div className='password'>
									<input type={this.isPasswordVisible ? 'text' : 'password'} autoComplete='new-password' {...inputProps} />
									<FontAwesomeIcon onClick={this.togglePasswordVisible} icon={this.isPasswordVisible ? faEye : faEyeSlash} />
								</div>
								{children}
							</div>
						))}
					</Row>
					{isTechFires && (
						<>
							<h3>{t('settingAccess')}</h3>
							<hr></hr>
							<Row>
								<div className='form-field'>
									<label>{t('region.title')}</label>
									<RecordSelect
										model={store.model.Region}
										property='name'
										filter={{ scope: { fields: ['id', 'name'] } }}
										value={this.region}
										onChange={this.onRegionChange}
										disabled={!store.isAdmin}
									/>
								</div>
								<div className='form-field'>
									<label>{t('county.title')}</label>
									<RecordSelect
										model={store.model.County}
										property='name'
										filter={{
											where: this.region ? { regionId: this.region.id } : undefined,
											scope: { fields: ['id', 'name', 'regionId'] },
										}}
										value={this.county}
										onChange={this.onCountyChange}
										disabled={!store.isAdmin}
									/>
								</div>
								<div className='form-field'>
									<label>{t('city.title')}</label>
									<RecordSelect
										model={store.model.City}
										property='name'
										filter={cityFilter}
										value={this.city}
										onChange={this.onCityChange}
										disabled={!store.isAdmin}
									/>
								</div>
							</Row>
							<div>
								{t('user.canAccess')}{' '}
								{this.city && (
									<>
										{t('user.limitCity')} {this.city.name} ({this.region?.name} {this.county?.name})
									</>
								)}
								{this.county && !this.city && (
									<>
										{t('user.limitCounty')} {this.county?.name} ({this.region?.name})
									</>
								)}
								{this.region && !this.city && !this.county && (
									<>
										{t('user.limitRegion')} {this.region?.name}
									</>
								)}
								{!this.city && !this.county && !this.region && <>{t('user.allData')}</>}
							</div>
							<Row className='techfire-org'>
								<div className='form-field'>
									<label>{t('organization.plural')}</label>
									<Input value={this.selectedTechFireOrg?.map((x) => x.name).join(', ')} onClick={this.toggleOrgTreePopup} disabled={!store.isAdmin} />
									{this.showOrgTreePopup && store.isAdmin && (
										<FormContext.Consumer>
											{(context) => (
												<Popup onClose={this.toggleOrgTreePopup} width={300}>
													<OrganizationsTreePopup allRecords={this.techFireOrganizations} selected={this.selectedTechFireOrg} context={context} />
												</Popup>
											)}
										</FormContext.Consumer>
									)}
								</div>
							</Row>
							<Row>
								<Field relation='fireDep' computed={renderFireDep} filter={{ include: ['headOrg'] }} label={t('fireDep.title')} disabled={!store.isAdmin} />
								<Field relation='garrison' property='name' filter={{ where: { isGarrison: true } }} label={t('garrison')} disabled={!store.isAdmin} />
							</Row>
						</>
					)}
					{showRoleSelect && (
						<>
							<h3>{t('role.plural')}</h3>
							<Field>
								<RecordSelect
									className='role-select'
									model={store.model.Role}
									// value={}
									noTotal
									maxItems={10}
									itemsPerPage={1000}
									isRequired
									property='description'
									onChange={onAddRole}
									showValue={rolesValue}
									filter={{ where: { id: { nin: this.roles.map(({ id }) => id) } } }}
								/>
							</Field>
						</>
					)}
				</Form>
			</div>
		);
	}
}
